// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import logo from "../images/moody-logo.png"
import map from "../images/moody-map.jpg"
import "../styles/footer.sass"
import "../styles/icons.css"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-4">
          <a href="https://moody.utexas.edu"><img src={logo} alt="Moody College of Communication" /></a>
          <div className="footer-text">
            <div>300 W. Dean Keeton (A0900)</div>
            <div>Austin, TX 78712-1069</div>
            <div>Phone: <a href="tel:15124715775">512-471-5775</a></div>
            <div><a href="https://moody.utexas.edu/about/moody-college-contacts">Contacts</a></div>
          </div>
          <ul className="social-links">
            <li><a href="https://www.facebook.com/moodycollege/"><span className="sr-only">Facebook</span><span className="moody-icon-facebook"></span></a></li>
            <li><a href="https://twitter.com/utexasmoody"><span className="sr-only">Twitter</span><span className="moody-icon-twitter-bird"></span></a></li>
            <li><a href="https://www.instagram.com/texasmoody"><span className="sr-only">Instagram</span><span className="moody-icon-instagram"></span></a></li>
            <li><a href="https://www.linkedin.com/school/15148740?pathWildcard=15148740"><span className="sr-only">LinkedIn</span><span className="moody-icon-linkedin"></span></a></li>
            <li><a href="https://www.youtube.com/user/collcomm"><span className="sr-only">YouTube</span><span className="moody-icon-youtube"></span></a></li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <ul className="footer-middle-menu">
            <li><a href="https://moody.utexas.edu/about">About</a></li>
            <li><a href="https://moody.utexas.edu/students">Students</a></li>
            <li><a href="https://moody.utexas.edu/academics">Academics</a></li>
            <li><a href="https://moody.utexas.edu/research">Research & Centers</a></li>
            <li><a href="https://moody.utexas.edu/media">Media</a></li>
            <li><a href="https://moody.utexas.edu/news">Stories</a></li>
            <li><a href="https://calendar.utexas.edu/moody-college">Events</a></li>
          </ul>
        </div>
        <div className="col-sm-12 col-lg-4">
          <ul className="footer-right-menu">
            <li><a href="https://www.utexas.edu/">UT Austin Home</a></li>
            <li><a href="https://www.utexas.edu/emergency">Emergency Information</a></li>
            <li><a href="https://www.utexas.edu/site-policies">Site Policies</a></li>
            <li><a href="https://www.utexas.edu/web-accessibility-policy">Web Accessibility Policy</a></li>
            <li><a href="https://www.utexas.edu/web-privacy-policy">Web Privacy Policy</a></li>
            <li><a href="https://get.adobe.com/reader/">Adobe Reader</a></li>
          </ul>
          <a href="https://www.google.com/maps/place/Moody+College+of+Communication/@30.2900884,-97.7409071,18.95z/data=!4m5!3m4!1s0x0000000000000000:0x8dd37da3a467cc45!8m2!3d30.2901933!4d-97.7407721"><img src={map} alt="Map of Moody College of Communication" /></a>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <p className="copyright">© The University of Texas at Austin 2023</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
