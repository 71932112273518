import React from "react"

const Brandbar = ({ siteTitle }) => (
  <div style={{
    display: `block`,
    backgroundColor: `#bf5700`,
    height: `34px`,
    overflow: `hidden`,
    position: `fixed`,
    zIndex: `1111`,
    right: `0`,
    left: `0`,
  }}>
    <a href="https://www.moody.utexas.edu"
      style={{
        float: `right`,
        marginRight: `1em`,
        marginTop: `5px`,
      }}>
      <span style={{
        position: `absolute`,
        width: `1px`,
        height: `1px`,
        padding: `0`,
        margin: `-1px`,
        overflow: `hidden`,
        clip: `rect(0,0,0)`,
        border: `0`,
      }}>Moody College of Communication</span>
      <span style={{
        maxHeight: `25px`,
        marginTop: `9px`,
        maxWidth: `100%`,
        height: `auto`,
      }}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="white" x="0px" y="0px" viewBox="0 0 166.79 23"
        style={{ enableBackground: `new 0 0 166.79 23`, width: `166.79px`,}}>
      <g>
          <path d="M7.81,6.74L7.81,6.74l0.02-0.01v0.01H7.81L7.81,6.74z M9.84,5.04c0.37,0.06,0.76,0.12,1.14,0.14l-0.1-2.84
            c-0.37-0.02-0.74-0.08-1.09-0.13C9.39,2.15,9.01,2.1,8.68,2.1c-0.26,0-0.46,0.03-0.63,0.1v0.39l0.09,2.38
            c0.15-0.04,0.32-0.06,0.52-0.06C9.01,4.91,9.42,4.98,9.84,5.04L9.84,5.04z M5.79,5.04c0.43-0.07,0.83-0.13,1.19-0.13
            c0.2,0,0.37,0.02,0.52,0.06l0.08-2.38V2.2C7.41,2.13,7.21,2.1,6.95,2.1c-0.33,0-0.71,0.06-1.11,0.11c-0.35,0.05-0.72,0.1-1.09,0.13
            l-0.1,2.84C5.03,5.16,5.41,5.1,5.79,5.04L5.79,5.04z M11.82,3.36h-0.33v0.73h0.33v0.54h-0.33v1h-0.23c-0.51,0-1.01-0.08-1.49-0.15
            C9.36,5.42,8.98,5.36,8.65,5.36c-0.27,0-0.46,0.04-0.62,0.12l0,0H7.6l0,0C7.45,5.4,7.25,5.36,6.98,5.36
            c-0.33,0-0.71,0.06-1.12,0.12C5.38,5.55,4.88,5.63,4.37,5.63H4.15v-1H3.82V4.09h0.33V3.36H3.82V2.82h0.33V1.9h0.22
            c0.48,0,0.95-0.07,1.41-0.14C6.2,1.7,6.59,1.64,6.96,1.64c0.34,0,0.63,0.05,0.86,0.16c0.24-0.11,0.52-0.16,0.86-0.16
            c0.37,0,0.76,0.06,1.18,0.12c0.46,0.07,0.93,0.14,1.41,0.14h0.23v0.92h0.33v0.54H11.82z M5.85,2.62c-0.02,0-0.04,0.01-0.07,0.01
            C5.57,2.66,5.35,2.69,5.14,2.72L5.13,3.08c0.24-0.03,0.48-0.06,0.71-0.1h0.01L5.85,2.62L5.85,2.62z M6.12,2.58v0.36
            C6.5,2.89,6.85,2.85,7.16,2.87l0.01-0.36C6.84,2.49,6.49,2.53,6.12,2.58L6.12,2.58z M5.85,4.27c-0.02,0-0.04,0.01-0.07,0.01
            c-0.23,0.03-0.47,0.07-0.7,0.09L5.07,4.73c0.26-0.03,0.52-0.06,0.77-0.1h0.01L5.85,4.27L5.85,4.27z M6.12,4.23v0.36
            C6.48,4.54,6.81,4.5,7.1,4.52l0.01-0.36C6.8,4.15,6.47,4.18,6.12,4.23L6.12,4.23z M5.79,3.45v0.36c0.01,0,0.03,0,0.04-0.01
            c0.48-0.07,0.92-0.14,1.29-0.11l0.01-0.36C6.73,3.32,6.27,3.38,5.79,3.45L5.79,3.45z M5.52,3.49C5.38,3.51,5.25,3.53,5.11,3.54
            L5.1,3.9c0.14-0.02,0.29-0.03,0.43-0.05L5.52,3.49L5.52,3.49z M9.35,2.56C9.04,2.52,8.74,2.5,8.46,2.52l0.01,0.36
            c0.26-0.02,0.56,0,0.88,0.04V2.56L9.35,2.56z M10.49,2.72c-0.22-0.02-0.43-0.06-0.64-0.09C9.77,2.62,9.7,2.61,9.62,2.6v0.36
            C9.68,2.97,9.74,2.98,9.8,2.99c0.23,0.03,0.47,0.07,0.71,0.1L10.49,2.72L10.49,2.72z M9.27,3.37C9,3.34,8.73,3.32,8.49,3.34
            L8.5,3.7c0.23-0.02,0.49,0,0.77,0.04V3.37L9.27,3.37z M10.52,3.54c-0.23-0.03-0.45-0.06-0.67-0.09c-0.1-0.01-0.2-0.03-0.3-0.04
            v0.36C9.63,3.78,9.72,3.79,9.8,3.81c0.24,0.04,0.49,0.07,0.74,0.1L10.52,3.54L10.52,3.54z M9.81,4.64c0.25,0.04,0.5,0.07,0.75,0.1
            l-0.01-0.36c-0.24-0.03-0.47-0.06-0.7-0.09c-0.01,0-0.02,0-0.03-0.01L9.81,4.64L9.81,4.64z M9.54,4.23
            C9.18,4.18,8.83,4.14,8.51,4.16l0.01,0.36C8.82,4.5,9.16,4.54,9.53,4.6L9.54,4.23L9.54,4.23z M7.35,10.05l-1.26,0.08l0.97,0.81
            l-0.31,1.22l1.07-0.67l1.07,0.67l-0.31-1.22l0.97-0.81l-1.26-0.08L7.82,8.88L7.35,10.05L7.35,10.05z M4.49,9.63
            c-0.01,0-0.02-0.01-0.01-0.02C4.48,9.6,4.49,9.6,4.5,9.6l0,0h2.52L7.8,7.21l0,0c0-0.01,0.01-0.02,0.02-0.02
            c0.01,0,0.02,0.01,0.02,0.02l0,0L8.62,9.6h2.52l0,0c0.01,0,0.02,0,0.02,0.01s0,0.02-0.01,0.02l0,0l-2.03,1.48l0.78,2.4
            c0,0.01,0,0.02,0,0.02H9.89l-2.05-1.49l-2.05,1.49H5.78l-0.01-0.01l0.78-2.41L4.49,9.63L4.49,9.63L4.49,9.63z M12.87,12.65
            c0.18-0.2,0.3-0.45,0.34-0.72c-0.26,0.06-0.5,0.2-0.69,0.41c-0.18,0.2-0.3,0.45-0.34,0.72C12.44,13,12.68,12.86,12.87,12.65
            L12.87,12.65z M11.88,11.07c0.04,0.27,0.15,0.52,0.33,0.72c0.12-0.24,0.17-0.52,0.13-0.79c-0.04-0.27-0.15-0.52-0.33-0.73
            C11.89,10.53,11.85,10.8,11.88,11.07L11.88,11.07z M12.43,9.01c0.05,0.27,0.18,0.51,0.37,0.7c0.11-0.25,0.14-0.52,0.09-0.79
            c-0.05-0.27-0.18-0.51-0.37-0.71C12.41,8.47,12.38,8.74,12.43,9.01L12.43,9.01z M13.73,9.61c-0.24,0.12-0.45,0.3-0.59,0.54
            c-0.14,0.24-0.2,0.51-0.18,0.78c0.24-0.12,0.45-0.3,0.59-0.54C13.68,10.15,13.75,9.88,13.73,9.61L13.73,9.61z M11.73,14.73
            c0.22-0.16,0.39-0.38,0.48-0.64c-0.27,0.01-0.53,0.09-0.75,0.26c-0.22,0.16-0.39,0.38-0.48,0.64
            C11.25,14.98,11.51,14.89,11.73,14.73L11.73,14.73z M11.1,12.98c-0.02,0.27,0.04,0.54,0.17,0.78c0.17-0.21,0.27-0.47,0.29-0.74
            c0.02-0.27-0.04-0.54-0.17-0.78C11.22,12.45,11.12,12.7,11.1,12.98L11.1,12.98z M10.39,14.81c0.08-0.26,0.07-0.54-0.01-0.8
            c-0.21,0.17-0.36,0.4-0.44,0.67c-0.08,0.26-0.07,0.54,0.01,0.8C10.16,15.3,10.31,15.07,10.39,14.81L10.39,14.81z M10.8,16
            c-0.27-0.05-0.54-0.02-0.79,0.1c-0.25,0.11-0.46,0.29-0.6,0.53c0.08,0.01,0.16,0.02,0.24,0.02l0,0c0.19,0,0.37-0.04,0.55-0.12
            C10.45,16.41,10.65,16.23,10.8,16L10.8,16z M8.45,16.11c-0.13,0.24-0.18,0.51-0.15,0.78c0.24-0.13,0.44-0.32,0.57-0.56
            s0.18-0.51,0.16-0.78C8.78,15.67,8.58,15.86,8.45,16.11L8.45,16.11z M5.41,16.52c0.25,0.11,0.52,0.14,0.79,0.1
            c-0.14-0.23-0.35-0.41-0.6-0.53c-0.25-0.11-0.53-0.14-0.79-0.1C4.95,16.23,5.16,16.41,5.41,16.52L5.41,16.52z M5.22,14.81
            c0.08,0.26,0.23,0.49,0.44,0.67c0.08-0.26,0.09-0.53,0.01-0.8c-0.08-0.26-0.23-0.49-0.44-0.66C5.14,14.27,5.14,14.55,5.22,14.81
            L5.22,14.81z M6.59,15.54c-0.03,0.27,0.02,0.54,0.16,0.78c0.13,0.24,0.33,0.44,0.57,0.56c0.03-0.27-0.02-0.54-0.15-0.78
            C7.02,15.86,6.83,15.67,6.59,15.54L6.59,15.54z M3.87,14.73c0.22,0.16,0.48,0.25,0.75,0.26c-0.09-0.26-0.26-0.48-0.48-0.64
            c-0.22-0.16-0.48-0.25-0.75-0.26C3.48,14.35,3.65,14.57,3.87,14.73L3.87,14.73z M4.21,12.23c-0.13,0.23-0.2,0.5-0.17,0.78
            c0.02,0.27,0.12,0.53,0.29,0.74c0.13-0.24,0.2-0.51,0.17-0.78C4.48,12.7,4.38,12.45,4.21,12.23L4.21,12.23z M2.74,12.65
            c0.18,0.2,0.42,0.34,0.69,0.41c-0.04-0.27-0.15-0.52-0.34-0.72C2.91,12.14,2.67,12,2.4,11.93C2.44,12.2,2.55,12.45,2.74,12.65
            L2.74,12.65z M2.64,10.93c0.02-0.27-0.04-0.54-0.18-0.78C2.32,9.91,2.12,9.73,1.87,9.61c-0.02,0.27,0.04,0.54,0.18,0.78
            C2.19,10.63,2.4,10.81,2.64,10.93L2.64,10.93z M2.71,8.92C2.66,9.19,2.69,9.46,2.8,9.71C2.99,9.52,3.12,9.27,3.17,9
            c0.05-0.27,0.02-0.54-0.09-0.79C2.9,8.41,2.77,8.65,2.71,8.92L2.71,8.92z M3.26,11.01c-0.04,0.27,0.01,0.54,0.13,0.79
            c0.18-0.2,0.3-0.45,0.33-0.73c0.04-0.27-0.01-0.54-0.13-0.79C3.41,10.49,3.29,10.74,3.26,11.01L3.26,11.01z M12.87,11.35
            l-0.16,0.05c-0.02,0.14-0.05,0.27-0.1,0.4c0.23-0.15,0.5-0.24,0.78-0.26l0.19-0.02v0.19c0.01,0.44-0.15,0.86-0.44,1.18
            s-0.69,0.52-1.13,0.56l-0.17,0.01c-0.05,0.13-0.11,0.26-0.18,0.37c0.19-0.07,0.39-0.11,0.6-0.11c0.07,0,0.15,0,0.22,0.01l0.19,0.02
            l-0.04,0.19c-0.08,0.43-0.33,0.81-0.68,1.06c-0.29,0.21-0.64,0.33-1.01,0.33l0,0c-0.07,0-0.14,0-0.21-0.01l-0.17-0.02
            c-0.07,0.12-0.16,0.23-0.25,0.33c0.27-0.04,0.55-0.02,0.82,0.07l0.18,0.06l-0.08,0.18c-0.17,0.4-0.48,0.72-0.88,0.9
            c-0.22,0.1-0.45,0.15-0.69,0.15l0,0c-0.19,0-0.38-0.03-0.56-0.09l-0.13-0.05c-0.22,0.19-0.48,0.38-0.83,0.62
            c0.16,0.1,0.33,0.22,0.53,0.34l-0.34,0.21c-0.19-0.12-0.36-0.23-0.52-0.34c-0.15,0.1-0.32,0.21-0.52,0.34l-0.34-0.21
            c0.2-0.13,0.37-0.24,0.53-0.34c-0.35-0.24-0.6-0.43-0.83-0.62L6.52,16.9c-0.18,0.06-0.37,0.09-0.56,0.09
            c-0.24,0-0.47-0.05-0.69-0.15c-0.4-0.18-0.71-0.5-0.88-0.9l-0.08-0.18l0.18-0.06c0.26-0.09,0.55-0.12,0.82-0.08
            c-0.1-0.1-0.18-0.21-0.25-0.33l-0.17,0.02c-0.07,0.01-0.14,0.01-0.21,0.01c-0.36,0-0.71-0.11-1.01-0.33
            c-0.35-0.26-0.59-0.63-0.68-1.06l-0.04-0.19l0.19-0.02c0.07-0.01,0.15-0.01,0.22-0.01c0.21,0,0.41,0.04,0.6,0.11
            c-0.08-0.12-0.14-0.24-0.18-0.37l-0.17-0.01c-0.44-0.04-0.84-0.23-1.13-0.56c-0.29-0.32-0.45-0.74-0.44-1.18v-0.19l0.19,0.02
            c0.28,0.02,0.55,0.11,0.78,0.26c-0.05-0.13-0.08-0.26-0.1-0.4l-0.16-0.05c-0.42-0.13-0.77-0.4-0.99-0.78S1.47,9.74,1.57,9.31
            l0.04-0.19l0.18,0.06C2,9.24,2.2,9.35,2.37,9.48c-0.04-0.21-0.04-0.43,0-0.64c0.08-0.43,0.32-0.81,0.68-1.06l0.16-0.11l0.1,0.16
            C3.54,8.2,3.62,8.64,3.54,9.07s-0.32,0.8-0.67,1.06c0.07,0.15,0.11,0.3,0.13,0.46c0.1-0.29,0.28-0.54,0.52-0.74l0.15-0.12
            l0.11,0.16c0.25,0.35,0.36,0.79,0.31,1.22c-0.06,0.43-0.27,0.82-0.61,1.1l-0.01,0.01c0.1,0.13,0.18,0.28,0.23,0.44
            c0.04-0.3,0.16-0.59,0.36-0.83l0.12-0.15l0.14,0.13c0.32,0.29,0.52,0.7,0.55,1.13c0.03,0.44-0.1,0.86-0.37,1.21l-0.01,0.01
            c0.13,0.11,0.23,0.24,0.32,0.38c-0.02-0.3,0.04-0.61,0.18-0.89l0.09-0.17l0.17,0.1c0.38,0.22,0.65,0.58,0.78,0.99
            c0.12,0.42,0.08,0.86-0.11,1.26v0.01c0.15,0.08,0.28,0.19,0.39,0.31c-0.08-0.29-0.09-0.6-0.01-0.9l0.05-0.19l0.18,0.06
            c0.41,0.14,0.76,0.43,0.96,0.81c0.2,0.37,0.25,0.8,0.16,1.21c0.06,0.04,0.11,0.08,0.17,0.12c0.06-0.04,0.12-0.08,0.17-0.12
            c-0.1-0.41-0.04-0.84,0.16-1.21c0.21-0.38,0.55-0.67,0.96-0.81l0.18-0.06l0.05,0.19c0.08,0.3,0.07,0.61-0.01,0.9
            c0.11-0.12,0.24-0.22,0.39-0.31v-0.01c-0.2-0.39-0.24-0.84-0.11-1.26c0.12-0.42,0.4-0.77,0.78-0.99l0.17-0.1l0.09,0.17
            c0.14,0.28,0.2,0.58,0.18,0.89c0.09-0.14,0.19-0.27,0.32-0.38l-0.01-0.01c-0.27-0.34-0.4-0.77-0.37-1.2
            c0.04-0.44,0.23-0.84,0.55-1.13l0.14-0.13l0.12,0.15c0.19,0.24,0.31,0.53,0.36,0.83c0.06-0.16,0.13-0.3,0.23-0.44l-0.01-0.01
            c-0.34-0.28-0.55-0.67-0.61-1.1s0.05-0.87,0.31-1.22l0.11-0.16l0.15,0.12c0.24,0.2,0.42,0.45,0.52,0.74
            c0.02-0.16,0.07-0.31,0.13-0.46c-0.35-0.26-0.59-0.63-0.67-1.06c-0.08-0.43,0-0.87,0.23-1.24l0.1-0.16l0.16,0.11
            c0.35,0.26,0.59,0.64,0.68,1.07c0.04,0.21,0.04,0.43,0,0.64c0.17-0.13,0.36-0.24,0.58-0.3l0.18-0.06l0.04,0.19
            c0.1,0.43,0.03,0.87-0.19,1.25C13.64,10.95,13.29,11.22,12.87,11.35L12.87,11.35z M12.91,15.16c1.77-2.6,2.01-5.61,2.01-7.95
            L7.81,6.82L0.7,7.21l0,0c0,2.34,0.23,5.35,2.01,7.95C3.08,15.7,3.5,16.19,4,16.68c1.56,1.43,3.31,2.23,3.81,2.43
            c0.49-0.21,2.25-1,3.81-2.43C12.13,16.19,12.55,15.69,12.91,15.16L12.91,15.16z M1.08,1.17L0.71,6.38h7.1h7.1l-0.37-5.21
            c-1.32-0.15-4.29-0.44-6.73-0.44C5.38,0.72,2.41,1.02,1.08,1.17L1.08,1.17z M15.64,0.57v6.5l0,0v0.12c0,2.45-0.24,5.61-2.13,8.38
            c-0.39,0.57-0.83,1.1-1.36,1.61l0,0l-0.01,0.01c-1.03,0.99-2.38,1.92-4.32,3l0,0l0,0l0,0l0,0c-1.94-1.08-3.29-2.01-4.32-3
            l-0.01-0.01l0,0c-0.53-0.51-0.97-1.04-1.36-1.61C0.24,12.8,0,9.64,0,7.19V6.38l0,0V0.57l0.32-0.04C0.36,0.52,4.56,0,7.83,0l0,0l0,0
            c3.27,0,7.47,0.52,7.51,0.53L15.64,0.57L15.64,0.57z M19.89,1.2v4.1h0.9l1.07-2.94l3.48-0.05v14.04l-2.78,0.3l0.12,0.81l3.89-0.07
            l3.75,0.07l0.12-0.81l-2.66-0.3V2.31l3.48,0.05l1.07,2.94h0.9V1.2l-6.67,0.07L19.89,1.2z M45.38,16.31l-6.37,0.07V9.43l3.24,0.09
            l0.93,2.43h0.81l-0.23-3.13l0.23-2.9h-0.81l-0.93,2.43l-3.24,0.09V2.28l6.14,0.07l1.3,3.01h0.79l-0.23-4.17l-9.2,0.07l-3.2-0.07
            L34.49,2l2.09,0.3v14.04l-2.09,0.3l0.12,0.81l3.2-0.07l9.2,0.07l0.81-4.29h-0.88L45.38,16.31z M78.33,16.4l1.81,0.26l-0.12,0.81
            l-3.2-0.07l-3.06,0.07l-0.12-0.81l1.97-0.3l-1.58-4.33h-5.72l-1.51,4.33l2.02,0.3l-0.12,0.81l-2.78-0.07l-1.9,0.06l-2.71-0.06
            L58,17.47l-0.12-0.83l1.97-0.3l-3.6-5.76l-3.95,5.78l1.9,0.28l-0.12,0.83l-2.71-0.07l-2.6,0.07l-0.12-0.83l1.81-0.25l5-6.88
            l-4.8-7.25l-1.81-0.25l0.12-0.81l3.31,0.09l3.29-0.09l0.12,0.81l-1.97,0.3l3.27,5.43l3.68-5.41l-2.02-0.3l0.12-0.81l2.71,0.07
            l2.71-0.07l0.12,0.81L62.5,2.28l-4.75,6.53l5.14,7.58L64,16.56l1.12-0.15l5.75-15.02L72.26,1L78.33,16.4z M73.63,10.93L71.1,3.98
            l-2.4,6.95C68.7,10.93,73.63,10.93,73.63,10.93z M88,8.36L85.33,7.2c-1.27-0.56-2.18-1.34-2.18-2.62c0-1.55,1.09-2.55,2.92-2.55
            c1.39,0,2.13,0.51,2.73,1.14l0.67,2.11h1.16V2.15c-1.16-0.86-2.78-1.34-4.24-1.34c-3.22,0.67-5.54,2.15-5.54,4.68
            c0,2.06,1.37,3.13,3.04,3.85l2.8,1.23c1.39,0.6,2.41,1.51,2.41,3.08c0,1.69-1.16,2.94-3.15,2.94c-1.3,0-2.57-0.6-3.27-1.46
            l-0.81-1.97h-1.62l0.51,3.13c1.23,0.97,2.67,1.58,4.8,1.58c4.36-0.9,5.84-3.01,5.84-5.19C91.43,10.31,89.83,9.15,88,8.36z
            M106.75,14.86l-0.19-1.3L101.69,1.3l-2.55,0.07L96.75,1.3l-0.12,0.81l1.97,0.35v14l-1.85,0.3l0.12,0.81l2.62-0.07l2.48,0.07
            l0.19-0.81l-2.04-0.3V3.16l0.86,2.69l4.66,11.7h1.44l5.86-14.39l-0.3,2.71v10.59l-2.09,0.3l0.12,0.81l3.36-0.07l2.78,0.07
            l0.12-0.81l-1.85-0.3V2.42l1.97-0.3l-0.12-0.81l-2.15,0.07l-2.57-0.07L106.75,14.86z M123.66,6.31c-3.59,0.6-5.7,2.57-5.7,6.05
            c0,3.15,2.09,5.54,5.17,5.54c3.59-0.58,5.7-2.57,5.7-6.02C128.83,8.72,126.75,6.31,123.66,6.31z M120.28,11.87
            c0-2.53,0.93-4.43,2.8-4.43c2.02,0,3.43,1.97,3.43,4.91c0,2.5-0.95,4.4-2.8,4.4C121.65,16.76,120.28,14.52,120.28,11.87z
            M136.04,6.31c-3.59,0.6-5.7,2.57-5.7,6.05c0,3.15,2.09,5.54,5.17,5.54c3.59-0.58,5.7-2.57,5.7-6.02
            C141.21,8.72,139.12,6.31,136.04,6.31z M132.66,11.87c0-2.53,0.93-4.43,2.8-4.43c2.02,0,3.43,1.97,3.43,4.91
            c0,2.5-0.95,4.4-2.8,4.4C134.02,16.76,132.66,14.52,132.66,11.87z M152.56,1.01L151.4,1.1l-2.78,0.72l0.12,0.79l1.62-0.05v4.38
            c-0.53-0.25-1.51-0.63-1.97-0.63c-3.38,0.46-5.68,2.43-5.68,6.19c0,3.2,1.88,5.4,4.82,5.4l2.99-2.02l0.72,1.78l3.11-0.58
            l-0.09-0.86h-1.3c-0.23-0.35-0.39-0.76-0.39-1.69L152.56,1.01L152.56,1.01z M147.44,16.05c-1.39,0-2.41-1.58-2.41-4.15
            c0-2.73,1.18-4.43,3.04-4.43c0.97,0,1.78,0.35,2.29,0.93v6.14l-0.7,0.56C148.85,15.74,148.07,16.05,147.44,16.05z M159.07,23
            l2.6-5.84l3.64-9.48l1.48-0.23l-0.12-0.81l-1.92,0.07l-2.04-0.07l-0.12,0.81l1.07,0.23l-2.76,8.39l-0.19-1.6l-2.46-6.79l1.53-0.23
            l-0.12-0.81l-2.43,0.07l-2.66-0.07l-0.12,0.81l1.44,0.23l3.78,9.78l0.93-0.28l-2.29,3.5h-1.99V23C156.32,23,159.07,23,159.07,23z"/>
        </g>
    </svg>
    </span>
  </a>
</div >
)

export default Brandbar
