import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../styles/header.sass"
import logo from "../images/main-logo.png"

class Header extends  React.Component {
  constructor(props) {
    super();
    this.state = {
      menuOpen: false,
      ariaExpanded: false,
    }
  }

  handleClick = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
      ariaExpanded: !this.state.ariaExpanded,
    });
  }

  render = () => {
    return(
      <header>
        <div className="container-lg">
          <div className="row">
            <div className="col-sm-12 d-flex justify-content-between">
              <h1 className="main-logo">
                <span className="sr-only">Dan Rather Medals for News and Guts</span>
                <a href="https://journalism.utexas.edu"><img src={logo} alt="Journalism and Media logo" /></a>
              </h1>
              <button aria-controls="main-nav" aria-expanded={this.state.ariaExpanded} aria-haspopup="true" aria-label="menu" className="ut-btn--toggle" href="#ut-main_menu-wrapper" id="js-nav-toggle" onClick={() => this.handleClick()}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="30" height="36" viewBox="0 0 24 28">
                  <title>Menu</title>
                  <path d={this.state.menuOpen ? "M20.281 20.656c0 0.391-0.156 0.781-0.438 1.062l-2.125 2.125c-0.281 0.281-0.672 0.438-1.062 0.438s-0.781-0.156-1.062-0.438l-4.594-4.594-4.594 4.594c-0.281 0.281-0.672 0.438-1.062 0.438s-0.781-0.156-1.062-0.438l-2.125-2.125c-0.281-0.281-0.438-0.672-0.438-1.062s0.156-0.781 0.438-1.062l4.594-4.594-4.594-4.594c-0.281-0.281-0.438-0.672-0.438-1.062s0.156-0.781 0.438-1.062l2.125-2.125c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l4.594 4.594 4.594-4.594c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l2.125 2.125c0.281 0.281 0.438 0.672 0.438 1.062s-0.156 0.781-0.438 1.062l-4.594 4.594 4.594 4.594c0.281 0.281 0.438 0.672 0.438 1.062z" : "M24 21v2c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-2c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1zM24 13v2c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-2c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1zM24 5v2c0 0.547-0.453 1-1 1h-22c-0.547 0-1-0.453-1-1v-2c0-0.547 0.453-1 1-1h22c0.547 0 1 0.453 1 1z" }></path>
                </svg>
                <span>{ this.state.menuOpen ? 'CLOSE' : 'MENU' }</span>
              </button>
            </div>
            <div className="col-sm-12 title-and-menu">
              <h2 className="site-name"><Link to="/">Dan Rather Medals for News and Guts</Link></h2>
              <nav id="main-menu" style={{
                transform: this.state.menuOpen ? `translateX(0)` : `translateX(100%)`,
              }}>
                <ul>
                  <li><Link to="/awards/" activeStyle={{ opacity: ".5" }}>Awards</Link></li>
                  <li><Link to="/apply/" activeStyle={{ opacity: ".5" }}>Nominate</Link></li>
                  <li><Link to="/winners/" activeStyle={{ opacity: ".5" }}>Winners</Link></li>
                  <li><Link to="/news/" activeStyle={{ opacity: ".5" }}>News, Guts and History</Link></li>
                  <li><Link to="/danrather/" activeStyle={{ opacity: ".5" }}>Dan Rather</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
